@charset "UTF-8";
body .progress-bar {
  background-color: #083041;
}
body h1 .badge {
  position: relative;
  top: -1.5rem;
  font-size: 14px;
}

html, body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

body {
  overflow-y: scroll;
}

body div {
  font-family: "noto sans", sans-serif;
  line-height: 1.5em;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  color: #083041;
}

h1.smaller {
  font-size: 32px;
}

ins {
  color: #5CA7A1;
  text-decoration: none;
}

del {
  color: #CC6161;
}

.versiondiff-viewport.versiondiff-hide del,
.versiondiff-viewport.versiondiff-hide tr.del,
.versiondiff-viewport.versiondiff-hide td.del,
.versiondiff-viewport.versiondiff-hide li.del {
  display: none;
}

.versiondiff-viewport.versiondiff-hide ins,
.versiondiff-viewport.versiondiff-hide label.ins,
.versiondiff-viewport.versiondiff-hide label.del {
  color: #212529;
}

#page {
  min-height: 100vh;
}
#page a:not(.btn, .active) {
  color: #335c67;
}
#page .underlined-links a:not(.btn) {
  text-decoration: underline;
}

#content {
  background-color: rgb(251.25, 251, 250.25);
  box-sizing: border-box;
  padding: 30px 20px 20px;
  box-shadow: inset 0 0 15px 3px rgba(0, 0, 0, 0.15);
}

pre {
  white-space: pre-wrap;
}

.controlAdminLandFields {
  display: none;
}

.btn-group.btn-group-custom a {
  margin-left: 3px !important;
}

#header {
  position: relative;
  color: #335c67;
  height: 100px;
}
#header #logo a {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 350px;
  background-image: url("../images/aconium-OP-Logo-20241.svg");
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
}
#header #logo a span {
  display: none;
}
#header #userinfo {
  position: absolute;
  right: 10px;
  top: 12px;
  text-align: right;
  font-weight: bold;
  line-height: 1.2em;
}
#header #userinfo .userinfo-meta {
  color: #7D8981;
}

.overheadline .overheadline-adjustment {
  margin-left: -15px;
  margin-top: -30px;
}

#mainmenu {
  z-index: 101;
  background-color: #083041;
  color: #ffffff;
  width: 60px;
  position: relative;
}
#mainmenu #mainmenu-toggle {
  display: block;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-size: 60px;
  line-height: 90px;
}
#mainmenu a:not(.btn),
#mainmenu a {
  color: #ffffff;
}
#mainmenu a:not(.btn):hover,
#mainmenu a:hover {
  color: #ffffff;
}
#mainmenu ul.mainmenu {
  background-color: #083041;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  opacity: 1;
}
#mainmenu ul.mainmenu li {
  list-style-type: none;
  white-space: nowrap;
  position: relative;
}
#mainmenu ul.mainmenu li a {
  line-height: 60px;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  padding: 0;
  border: 0 solid transparent;
}
#mainmenu ul.mainmenu li a .icon {
  background-size: 50px;
  height: 60px;
  width: 60px;
}
#mainmenu ul.mainmenu li a .icon svg {
  top: -2px;
  left: 5px;
  width: 50px !important;
  height: 50px !important;
  fill: white !important;
}
#mainmenu ul.mainmenu li a .btn-label {
  display: none;
  text-align: left;
  line-height: 60px;
  margin-left: 60px;
  padding-right: 40px;
}
#mainmenu ul.mainmenu li a .menu-badge {
  position: absolute;
  top: -15px;
  left: 35px;
  line-height: 60px;
}
#mainmenu ul.mainmenu li a.level-2 {
  display: none;
  margin-left: 60px;
}
#mainmenu ul.mainmenu li[class] a {
  /* legacy - kann später weg */
  display: none;
}
#mainmenu:hover ul.mainmenu a.level-2 {
  display: block;
}
#mainmenu:hover ul.mainmenu li a .btn-label {
  display: block;
}
#mainmenu:hover ul.mainmenu li[class] a {
  display: block;
}

#breadcrumb {
  background-color: #335c67;
  color: #ffffff;
  position: sticky;
  z-index: 100;
  top: 0;
}
#breadcrumb a {
  color: #ffffff;
}
#breadcrumb a svg {
  fill: #ffffff;
  left: 3px;
  top: 1px;
  transform: scale(1.1);
}
#breadcrumb a:hover {
  color: #ffffff;
}
#breadcrumb a:hover svg {
  fill: #ffffff;
}
#breadcrumb .breadcrumb-current .btn:before,
#breadcrumb .breadcrumb-back a:after,
#breadcrumb .breadcrumb-path a:not(:nth-last-of-type(-n+2)):after {
  content: "/";
  display: inline-block;
  position: relative;
  left: 15px;
  top: -1px;
}
#breadcrumb .breadcrumb-path a:last-of-type {
  display: none;
}
#breadcrumb .breadcrumb-back a:after {
  content: "|";
}
#breadcrumb .breadcrumb-current .btn:before {
  left: -15px;
}
#breadcrumb .breadcrumb-current .btn {
  cursor: default;
  color: #ffffff;
}
#breadcrumb .scroll-to-top {
  right: 40px;
}

#footer {
  /*
  position: sticky;
  bottom: 0;
  */
  background-color: #ffffff;
  color: #7D8981;
  padding: 0;
  height: 116px;
  border-top: 2px solid #335c67;
}
#footer a:not(.btn),
#footer a {
  color: #7D8981;
}

#copyright,
#footermenu,
#footermenu ul,
#footermenu ul li {
  display: inline-block;
  line-height: 128px;
}

#copyright {
  padding-top: 41.3333333333px;
  line-height: 42.6666666667px;
  float: left;
}

#partnerlogo {
  z-index: 2;
  float: left;
  display: inline-block;
  width: 168px;
  height: 104px;
  position: relative;
}
#partnerlogo span {
  position: relative;
  left: 25px;
  font-size: 12px;
  line-height: 12px;
  color: black;
}
#partnerlogo a {
  display: block;
  width: 168px;
  height: 104px;
  background-image: url("../images/bmdv_2021_de.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: width 0.2s, height 0.2s, box-shadow 0.2s;
  background-color: white;
}
#partnerlogo a span {
  display: none;
}

#bfplogo {
  z-index: 1;
  width: 208px;
  height: 100px;
  float: left;
}
#bfplogo a {
  display: block;
  width: 208px;
  height: 100px;
  background-image: url("../images/BFP_Logo.svg");
  background-size: auto 80%;
  background-repeat: no-repeat;
  background-position: center;
}
#bfplogo a span {
  display: none;
}

#footermenu {
  float: right;
  margin-right: 40px;
}
#footermenu ul {
  margin: 0;
  padding: 0;
}
#footermenu ul li {
  list-style-type: none;
  padding-left: 30px;
}

#page {
  grid-column-gap: 0;
  grid-row-gap: 0;
  grid-template-columns: 60px 1fr;
  grid-template-rows: 100px auto auto 1fr 130px;
  display: grid;
  grid-template-areas: "header header" "maintenance maintenance" "mainmenu breadcrumb" "mainmenu content" "footer footer";
}

#header {
  grid-area: header;
}

#footer {
  z-index: 21;
  grid-area: footer;
}

#breadcrumb {
  grid-area: breadcrumb;
}

#mainmenu {
  grid-area: mainmenu;
}

#content {
  grid-area: content;
}

#maintenance {
  grid-area: maintenance;
}

@media (max-width: 1600px) {
  .only-xl {
    display: none;
  }
}
.nobr {
  white-space: nowrap;
}

div.children-align-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

fieldset.hidden {
  display: none;
}

fieldset .multiple-expanded-choice {
  height: 200px;
  overflow-y: auto;
}

.fs-no-margin-bottom fieldset {
  margin-bottom: 0;
}

.form-help-text .icon svg {
  fill: #6c757d;
}

input.float-loaded {
  text-align: right;
}

.faded {
  display: none !important;
}

form .custom-file-label::after {
  content: "Durchsuchen";
}

form div.custom-file * {
  cursor: pointer;
}

fieldset div.is-invalid {
  flex-flow: nowrap;
  padding: 0;
  border: 0;
  background-image: none !important;
}
fieldset div.is-invalid input,
fieldset div.is-invalid select {
  border-color: #CC6161;
}

input.no-readonly-background[readonly] {
  background-color: inherit;
}

div.custom-switch {
  margin-top: 15px;
}

div.versionZeitstempel {
  margin-top: 15px;
}

.max-height {
  max-height: 50vh;
  overflow-y: auto;
}

#loading-gif {
  min-height: 20px;
  background-image: url("../images/loader.gif");
  background-repeat: no-repeat;
}

div.radio-inline div.form-check-inline:first-child {
  padding-left: 0.5rem;
}

table.table-sm div.form-group {
  margin-bottom: 0;
}

.label-hinweis-italic {
  color: #495057;
  font-size: 11pt;
  margin-bottom: 5px;
  font-style: italic;
}

.margin-bottom-zero {
  margin-bottom: 0;
}

span.padding-like-form-control {
  padding: 0.375rem 0.75rem;
}

.addmessageAlignText {
  word-break: break-word;
  max-width: 95%;
}

a.btn.disabled {
  pointer-events: all !important;
  cursor: not-allowed;
}

body {
  /* rest siehe webpack/bootstrap/_buttons.scss */
}
body .btn {
  display: inline-block;
  white-space: nowrap;
  width: max-content;
}
body .btn[disabled], body .btn.disabled {
  pointer-events: all;
  cursor: not-allowed;
}
body .btn.btn-max {
  text-align: left;
  width: 100%;
}
body .btn.btn-allow-wrap .btn-label {
  white-space: normal;
}
body .btn .btn-label {
  line-height: 1em;
  padding-left: 6px;
}

.btn.checkbutton:not(:disabled):not(.disabled),
.btn.checkbutton {
  cursor: default;
}
.btn.checkbutton:not(:disabled):not(.disabled) input.checkbutton-input,
.btn.checkbutton input.checkbutton-input {
  cursor: pointer;
  position: relative;
  top: 5px;
  left: -5px;
}
.btn.checkbutton:not(:disabled):not(.disabled) label,
.btn.checkbutton label {
  cursor: pointer;
  position: relative;
  top: 2px;
}

div.btn-group-projektphase button.btn {
  margin-right: 0.5rem;
  margin-bottom: 1rem;
}

table tr .meta {
  padding: 50px;
  text-align: center;
  color: #7D8981;
  font-size: 2em;
  opacity: 0.5;
  box-shadow: inset 0 0 5px 3px rgba(0, 0, 0, 0.05);
}
table tr .meta span.bi {
  position: relative;
}

.pagination-status {
  margin-top: -0.75rem;
  margin-bottom: 0.75rem;
  opacity: 0.66;
}

table.table-sm tr .meta {
  font-size: 1em;
  padding: 20px;
}

.table-sm .tr-href-loaded td:last-of-type {
  background-image: url(../images/Icons/Weiter.svg);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 25px 25px;
  padding-right: 25px !important;
}

#page .kachel,
#page .panel {
  background-color: #ffffff;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.15);
  padding: 20px;
  margin-bottom: 20px;
}
#page .kachel .h3, #page .kachel h3,
#page .panel .h3,
#page .panel h3 {
  color: #083041;
  font-size: 16px;
  font-weight: bold;
}
#page .kachel h2 a,
#page .panel h2 a {
  color: #083041;
}

#page .kachel-inverse,
#page .kachel-inverse a:not(.btn),
#page .kachel-inverse a.btn-link,
#page .kachel-inverse h1, #page .kachel-inverse h2, #page .kachel-inverse h3, #page .kachel-inverse h4, #page .kachel-inverse h5, #page .kachel-inverse h6 {
  background-color: #335c67;
  color: #ffffff;
}
#page .kachel-inverse .text-with-icon svg {
  fill: #ffffff;
}

#page .row {
  margin-left: -10px;
  margin-right: -10px;
}
#page .row .text-left .icon {
  opacity: 0;
  width: 2em;
  height: 0.1em;
  margin-bottom: 0;
}
#page .row .text-left .icon.icon-replaced {
  transition: opacity 0.5s;
  opacity: 0.6;
  fill: #083041;
  height: 2em;
}

#page .tr-href-loaded:hover .row .text-left .icon.icon-replaced {
  opacity: 1;
}

.hr-inverse {
  border-top-color: #ffffff;
}

#page .no-padding {
  padding: 0;
}

.kachelbutton h2, .kachel-folded-up .header h2, .kachel-folded h2, .kachelbuttonadd h2, .kachelbuttonleftside h2, .kachelbutton h3, .kachel-folded-up .header h3, .kachel-folded h3, .kachelbuttonadd h3, .kachelbuttonleftside h3, .kachelbutton h4, .kachel-folded-up .header h4, .kachel-folded h4, .kachelbuttonadd h4, .kachelbuttonleftside h4, .kachelbutton h5, .kachel-folded-up .header h5, .kachel-folded h5, .kachelbuttonadd h5, .kachelbuttonleftside h5, .kachelbutton h6, .kachel-folded-up .header h6, .kachel-folded h6, .kachelbuttonadd h6, .kachelbuttonleftside h6 {
  border-left: 8px solid white;
  padding-left: 10px;
  margin-right: -20px;
  background-image: url(../images/Icons/Weiter.svg);
  background-position: right -12px;
  background-repeat: no-repeat;
  background-size: 60px 60px;
}
.kachelbutton h2 a, .kachel-folded-up .header h2 a, .kachel-folded h2 a, .kachelbuttonadd h2 a, .kachelbuttonleftside h2 a, .kachelbutton h3 a, .kachel-folded-up .header h3 a, .kachel-folded h3 a, .kachelbuttonadd h3 a, .kachelbuttonleftside h3 a, .kachelbutton h4 a, .kachel-folded-up .header h4 a, .kachel-folded h4 a, .kachelbuttonadd h4 a, .kachelbuttonleftside h4 a, .kachelbutton h5 a, .kachel-folded-up .header h5 a, .kachel-folded h5 a, .kachelbuttonadd h5 a, .kachelbuttonleftside h5 a, .kachelbutton h6 a, .kachel-folded-up .header h6 a, .kachel-folded h6 a, .kachelbuttonadd h6 a, .kachelbuttonleftside h6 a {
  display: block;
}
.kachelbutton h2 a:hover, .kachel-folded-up .header h2 a:hover, .kachel-folded h2 a:hover, .kachelbuttonadd h2 a:hover, .kachelbuttonleftside h2 a:hover, .kachelbutton h3 a:hover, .kachel-folded-up .header h3 a:hover, .kachel-folded h3 a:hover, .kachelbuttonadd h3 a:hover, .kachelbuttonleftside h3 a:hover, .kachelbutton h4 a:hover, .kachel-folded-up .header h4 a:hover, .kachel-folded h4 a:hover, .kachelbuttonadd h4 a:hover, .kachelbuttonleftside h4 a:hover, .kachelbutton h5 a:hover, .kachel-folded-up .header h5 a:hover, .kachel-folded h5 a:hover, .kachelbuttonadd h5 a:hover, .kachelbuttonleftside h5 a:hover, .kachelbutton h6 a:hover, .kachel-folded-up .header h6 a:hover, .kachel-folded h6 a:hover, .kachelbuttonadd h6 a:hover, .kachelbuttonleftside h6 a:hover {
  text-decoration: none;
}
.kachelbutton .content, .kachel-folded-up .header .content, .kachel-folded .content, .kachelbuttonadd .content, .kachelbuttonleftside .content {
  margin-left: 20px;
  margin-right: 20px;
}
.kachelbutton .content > table, .kachel-folded-up .header .content > table, .kachel-folded .content > table, .kachelbuttonadd .content > table, .kachelbuttonleftside .content > table {
  margin-top: 0.5rem;
}

.kachelbuttonleftside h2, .kachelbuttonleftside h3, .kachelbuttonleftside h4, .kachelbuttonleftside h5, .kachelbuttonleftside h6 {
  border-left: 0 solid white;
  padding-left: 0;
  margin-bottom: 0.5rem;
}

.kachelbuttonadd h2 {
  background-image: url(../images/Icons/Hinzufuegen.svg);
}

#top > .introduction {
  margin-bottom: 20px;
}

.dl-inverted > dt,
.dl-inverted > dd a {
  font-weight: normal;
}

.dl-inverted > dd {
  font-weight: 700;
}

.dl-horizontal dt {
  float: left;
  width: 200px;
  clear: left;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dl-horizontal dd {
  margin-left: 220px;
  margin-bottom: 0;
}

iframe {
  border: 2px solid rgb(251.25, 251, 250.25);
  width: 100%;
  height: 50vh;
  min-height: 500px;
}

.iframe.meta {
  line-height: 50vh;
  text-align: center;
  font-weight: bold;
  background-color: rgb(251.25, 251, 250.25);
  border: 2px solid #7D8981;
  color: #e0e0e0;
  font-size: 2em;
  opacity: 0.5;
  box-shadow: inset 0 0 5px 3px rgba(0, 0, 0, 0.05);
}

.list-group-item.active {
  background-color: #335c67;
  border-color: #335c67;
  z-index: 0;
}

.kachel-folded {
  cursor: pointer;
}
.kachel-folded h2, .kachel-folded h3, .kachel-folded h4, .kachel-folded h5, .kachel-folded h6 {
  background-image: url(../images/Icons/Drop-Down-Aufklappen.svg);
  background-repeat: no-repeat;
}
.kachel-folded .content,
.kachel-folded .footer,
.kachel-folded .actions {
  display: none;
}

.kachel-folded-up .header h2, .kachel-folded-up .header h3, .kachel-folded-up .header h4, .kachel-folded-up .header h5, .kachel-folded-up .header h6 {
  background-image: url(../images/Icons/Drop-Down-Zuklappen.svg);
  background-repeat: no-repeat;
  cursor: pointer;
  border-left: 0 solid white;
  padding-left: 0;
  margin-bottom: 0.5rem;
}

#page nav.kachelbutton-disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}
#page nav.kachelbutton-disabled * {
  color: #7D8981;
}
#page nav.kachelbutton-disabled h2 {
  border-color: #7D8981;
}
#page nav.kachelbutton-disabled a {
  pointer-events: none;
}

.icon {
  display: inline-block;
  background-size: 130%;
  background-position: center;
  width: 1.5em;
  height: 1.5em;
  position: relative;
  top: 0.3em;
}
.icon svg {
  position: relative;
}

.icon-big { /* nur für debug-seite */
  transform: scale(2);
}

.btn-icon {
  line-height: 1em;
}
.btn-icon span {
  line-height: 1.5em;
}

.icon-replaced {
  background-image: none !important;
}

.badge-rounded {
  border-radius: 0.5rem;
}

.badge .icon {
  width: 2em;
  height: 2em;
}
.badge .icon svg {
  left: 0.175em;
  top: 0.175em;
}

.btn .icon {
  top: 0;
  float: left;
}
.btn .icon.bi {
  top: 0;
}
.btn .icon:before {
  transform: scale(1.25);
}

.input-group-text .icon,
.text-with-icon .icon,
table .icon {
  top: 0;
}
.input-group-text .icon svg,
.text-with-icon .icon svg,
table .icon svg {
  left: 3px;
}

table .afu-uploaded .icon svg {
  left: 2px;
  top: 0;
}

.text-with-icon .icon {
  top: 0.05em;
  left: -0.05em;
}

.list-group-item-info svg {
  fill: #4263BE !important;
}

.text-info a:not(.btn-info) svg,
.alert-info a:not(.btn-info) svg {
  fill: #4263BE !important;
}

.text-success svg,
.alert-success svg {
  fill: #5CA7A1 !important;
}

.text-warning svg {
  fill: #F0CE70 !important;
}

.alert-warning svg {
  fill: #664d03 !important;
}

.text-danger svg,
.alert-danger svg {
  fill: #CC6161 !important;
}

.text-primary svg,
.alert-primary svg {
  fill: #083041 !important;
}

.text-default svg,
.alert-default svg {
  fill: #335c67 !important;
}

.btn-link:hover svg,
.list-group-item-info:hover svg {
  fill: #000000 !important;
}

.icon-add,
.icon-plus {
  background-image: url(../images/Icons/Hinzufuegen.svg);
}

.icon-back {
  background-image: url(../images/Icons/Zurueck.svg);
}

.icon-edit {
  background-image: url(../images/Icons/Bearbeiten-Zeichnen.svg);
}

.icon-view {
  background-image: url(../images/Icons/Details_ansehen.svg);
}

.icon-trash,
.icon-delete {
  background-image: url(../images/Icons/Loeschen-20.svg);
}

.icon-erase {
  background-image: url(../images/Icons/Radieren.svg);
}

.icon-checked,
.icon-check {
  background-image: url(../images/Icons/Haekchen.svg);
}

.icon-refresh {
  background-image: url(../images/Icons/refresh.svg);
}

.icon-calc {
  background-image: url(../images/Icons/Taschenrechner.svg);
}

.icon-unchecked,
.icon-cross {
  background-image: url(../images/Icons/Schliessen.svg);
}

.icon-submit,
.icon-send {
  background-image: url(../images/Icons/Senden-Antrag_einreichen.svg);
}

.icon-save {
  background-image: url(../images/Icons/Speichern.svg);
}

.icon-search {
  background-image: url(../images/Icons/Suche.svg);
}

.icon-clone,
.icon-share {
  background-image: url(../images/Icons/Teilen.svg);
}

.icon-copy {
  background-image: url(../images/Icons/Kopieren.svg);
}

.icon-share-alt {
  background-image: url(../images/Icons/Teilenv2.svg);
}

.icon-share-alt-2 {
  background-image: url(../images/Icons/Teilenv3.svg);
}

.icon-upload {
  background-image: url(../images/Icons/Upload.svg);
}

.icon-download {
  background-image: url(../images/Icons/Download.svg);
}

a.external-link:before {
  content: "";
  background-image: url(../images/Icons/Teilenv3.svg);
  height: 23px;
  width: 23px;
  fill: red;
  color: red;
  display: inline-block;
  background-size: 24px;
  position: relative;
  top: 5px;
}
a.external-link:before svg g {
  fill: red !important;
}

.icon-ui-arrow-left {
  background-image: url(../images/Icons/Zurueck.svg);
}

.icon-ui-arrow-right {
  background-image: url(../images/Icons/Weiter.svg);
}

.icon-ui-arrow-up {
  background-image: url(../images/Icons/Drop-Down-Zuklappen.svg);
}

.icon-ui-arrow-down {
  background-image: url(../images/Icons/Drop-Down-Aufklappen.svg);
}

.icon-ui-min {
  background-image: url(../images/Icons/Minimieren.svg);
}

.icon-ui-max {
  background-image: url(../images/Icons/Maximieren.svg);
}

.icon-ui-folder {
  background-image: url(../images/Icons/Ordner-geschlossen.svg);
}

.icon-ui-folder-open {
  background-image: url(../images/Icons/Dokumente-Ordner_offen.svg);
}

.icon-ui-close {
  background-image: url(../images/Icons/Schliessen.svg);
}

.icon-info-outline {
  background-image: url(../images/Icons/Info-outline.svg);
}

.icon-info-filled {
  background-image: url(../images/Icons/Info-filled.svg);
}

.icon-ui-menu {
  background-image: url(../images/Icons/Burgermenue.svg);
}

.icon-status-abgelehnt {
  background-image: url(../images/Icons/Status_Abgelehnt.svg);
}

.icon-status-ausbezahlt {
  background-image: url(../images/Icons/Status_Ausbezahlt.svg);
}

.icon-status-bewilligt {
  background-image: url(../images/Icons/Status_Bewilligt.svg);
}

.icon-status-bewilligt-mit-aenderung {
  background-image: url(../images/Icons/Status_Bewilligt-mit-Aenderung.svg);
}

.icon-status-eingegangen {
  background-image: url(../images/Icons/Status-Eingegangen.svg);
}

.icon-status-in-bearbeitung {
  background-image: url(../images/Icons/Status_In-Bearbeitung.svg);
}

.icon-filetype-jpg {
  background-image: url(../images/Icons/JPG.svg);
}

.icon-filetype-csv {
  background-image: url(../images/Icons/CSV.svg);
}

.icon-filetype-pdf {
  background-image: url(../images/Icons/PDF.svg);
}

.icon-filetype-xls {
  background-image: url(../images/Icons/Exel.svg);
}

/* @see _base_mainmenu_icons.scss */
.icon-menu-austauschplattform {
  background-image: url(../images/Icons/Austauschplattform.svg);
}

.icon-menu-auswahlverfahren {
  background-image: url(../images/Icons/Auswahlverfahren.svg);
}

.icon-menu-bundeslaender {
  background-image: url(../images/Icons/Bundeslaender.svg);
}

.icon-menu-dashboard {
  background-image: url(../images/Icons/Dashboard.svg);
}

.icon-menu-datenschutz {
  background-image: url(../images/Icons/Datenschutz.svg);
}

.icon-menu-drucken {
  background-image: url(../images/Icons/Drucken.svg);
}

.icon-menu-email {
  background-image: url(../images/Icons/Email.svg);
}

.icon-menu-warnung {
  background-image: url(../images/Icons/Error-Warning.svg);
}

.icon-menu-exportieren {
  background-image: url(../images/Icons/Exportieren.svg);
}

.icon-menu-favorit {
  background-image: url(../images/Icons/Favorit.svg);
}

.icon-menu-feedback {
  background-image: url(../images/Icons/Feedback.svg);
}

.icon-menu-filter {
  background-image: url(../images/Icons/Filter.svg);
}

.icon-menu-foerdermittelantraege {
  background-image: url(../images/Icons/Foerdermittelantraege.svg);
}

.icon-menu-gebietskarte {
  background-image: url(../images/Icons/Gebietskarte.svg);
}

.icon-menu-grundlage-z-b {
  background-image: url(../images/Icons/Grundlage-zur-Berechnung.svg);
}

.icon-menu-hilfe {
  background-image: url(../images/Icons/Hilfe.svg);
}

.icon-menu-kalender-eintragen {
  background-image: url(../images/Icons/Im-Kalender-eintragen.svg);
}

.icon-menu-impressum {
  background-image: url(../images/Icons/Impressum.svg);
}

.icon-menu-infobereich-antrag {
  background-image: url(../images/Icons/Infobereich-Antrag.svg);
}

.icon-menu-kalender {
  background-image: url(../images/Icons/Kalender.svg);
}

.icon-menu-kontakt {
  background-image: url(../images/Icons/Kontakt.svg);
}

.icon-menu-krankenhaus {
  background-image: url(../images/Icons/Krankenhaus.svg);
}

.icon-menu-livechat {
  background-image: url(../images/Icons/Livechat.svg);
}

.icon-menu-login {
  background-image: url(../images/Icons/Login.svg);
}

.icon-menu-logout {
  background-image: url(../images/Icons/Logout.svg);
}

.icon-menu-mev {
  background-image: url(../images/Icons/MEV.svg);
}

.icon-menu-monitoring {
  background-image: url(../images/Icons/Monitring.svg);
}

.icon-menu-nachforderung {
  background-image: url(../images/Icons/Nachforderung.svg);
}

.icon-menu-nutzer {
  background-image: url(../images/Icons/Nutzer.svg);
}

.icon-menu-op-export {
  background-image: url(../images/Icons/OP_Export.svg);
}

.icon-menu-phone {
  background-image: url(../images/Icons/Phone.svg);
}

.icon-menu-postfach {
  background-image: url(../images/Icons/Postfach.svg);
}

.icon-menu-projekte {
  background-image: url(../images/Icons/Projekte.svg);
}

.icon-menu-projektkoordinator {
  background-image: url(../images/Icons/Projektkoordinator.svg);
}

.icon-menu-qrcode {
  background-image: url(../images/Icons/QR-Code.svg);
}

.icon-menu-rolle-berechtigung {
  background-image: url(../images/Icons/Rolle-Berechtigung.svg);
}

.icon-menu-rolle-hinzu {
  background-image: url(../images/Icons/Rolle-hinzufuegen.svg);
}

.icon-menu-schluessel {
  background-image: url(../images/Icons/Schluessel.svg);
}

.icon-menu-schule {
  background-image: url(../images/Icons/Schule.svg);
}

.icon-menu-settings {
  background-image: url(../images/Icons/Settings.svg);
}

.icon-menu-wissen {
  background-image: url(../images/Icons/Wissen.svg);
}

table.table-detail tr th:first-of-type {
  width: 20%;
}
table tr.tr-href-loaded td {
  cursor: pointer;
}
table tr .max-width {
  width: 100%;
}
table tr .custom-max-width {
  width: 60%;
}
table tr .smallest {
  width: 0.1%;
  white-space: nowrap;
}
table tr .smallest > * {
  white-space: normal;
}
table tr .number,
table tr .date,
table tr .currency,
table tr .filesize {
  text-align: right;
  white-space: nowrap;
  width: 0.1%;
}
table tr .number > *,
table tr .date > *,
table tr .currency > *,
table tr .filesize > * {
  white-space: normal;
}
table tr td:has(.btn) .btn,
table tr .action .btn {
  width: max-content;
  white-space: nowrap;
}
table tr td:has(.btn) .input-group .btn,
table tr .action .input-group .btn {
  width: max-content;
  white-space: nowrap;
  margin: 0px -1px !important;
}
table tr td table.table {
  margin-bottom: 0;
}
table tr ul.list-group li.list-group-item {
  padding: 10px 0.75rem;
}
table col.smallest {
  width: 0.1%;
}
table .innerTableWithoutMargin {
  margin: 0;
}
table .innerTableWithoutMargin td, table .innerTableWithoutMargin th {
  padding-left: 0;
}
table .thTextRight {
  text-align: right;
}
table .thTextLeft {
  text-align: left;
}
table .tdTextRight {
  text-align: right;
}
table .tdTextLeft {
  text-align: left;
}

table.infrastructure tr td label {
  margin-bottom: 0;
}

div.table-responsive {
  max-width: 92vw;
  border-right: 3px double #dee2e6;
  margin-bottom: 1rem;
}
div.table-responsive table {
  margin-bottom: 0;
  margin-right: 10px;
}

.nachfordern {
  color: #212529 !important;
  background-color: #F0CE70 !important;
}

.infoAlert {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

table.border-success thead th {
  border-top-color: #5CA7A1 !important;
}
table.border-success thead th:first-of-type {
  border-left-color: #5CA7A1 !important;
}
table.border-success thead th:last-of-type {
  border-right-color: #5CA7A1 !important;
}
table.border-success tbody tr th,
table.border-success tbody tr td:first-of-type {
  border-left-color: #5CA7A1 !important;
}
table.border-success tbody tr td:last-of-type {
  border-right-color: #5CA7A1 !important;
}
table.border-success tbody tr:last-child td, table.border-success tbody tr:last-child th {
  border-bottom-color: #5CA7A1 !important;
}

table.border-primary thead th {
  border-top-color: #083041 !important;
}
table.border-primary thead th:first-of-type {
  border-left-color: #083041 !important;
}
table.border-primary thead th:last-of-type {
  border-right-color: #083041 !important;
}
table.border-primary tbody tr th,
table.border-primary tbody tr td:first-of-type {
  border-left-color: #083041 !important;
}
table.border-primary tbody tr td:last-of-type {
  border-right-color: #083041 !important;
}
table.border-primary tbody tr:last-child td, table.border-primary tbody tr:last-child th {
  border-bottom-color: #083041 !important;
}

table.border-secondary thead th {
  border-top-color: #7D8981 !important;
}
table.border-secondary thead th:first-of-type {
  border-left-color: #7D8981 !important;
}
table.border-secondary thead th:last-of-type {
  border-right-color: #7D8981 !important;
}
table.border-secondary tbody tr th,
table.border-secondary tbody tr td:first-of-type {
  border-left-color: #7D8981 !important;
}
table.border-secondary tbody tr td:last-of-type {
  border-right-color: #7D8981 !important;
}
table.border-secondary tbody tr:last-child td, table.border-secondary tbody tr:last-child th {
  border-bottom-color: #7D8981 !important;
}

.modal-supersize {
  width: 90vw;
}

.modal-body .modal-footer {
  margin: 0 -1rem -1rem -1rem;
}

#content .content-modal {
  background-color: inherit;
  box-shadow: none;
  padding-top: 0;
  padding-bottom: 0;
}

#content.content-modal.embedFormAsPartOfView {
  padding: 0;
}

#content.content-modal.embedFormAsPartOfView .header h1 {
  font-size: 32px;
}

#modal-footer-left-align-button.modal-footer {
  justify-content: flex-start;
}

#pagemodal {
  display: inherit;
}

#pagemodal.embedFormAsPartOfView {
  margin-top: 4mm;
}

/* globalMessages */
#maintenance #messages .maintenance {
  padding: 10px 20px 10px 82px;
  cursor: pointer;
}
#maintenance #messages .maintenance .title {
  font-weight: bold;
}
#maintenance #messages .maintenance:hover .titletext {
  text-decoration: underline;
}
#maintenance #messages .maintenance .titletext:after {
  content: "…";
  padding-left: 3px;
}
#maintenance #messages .maintenance .description {
  text-align: justify;
  padding-top: 10px;
  display: none;
}
#maintenance #messages .maintenance.unfolded .titletext:after {
  content: "";
}
#maintenance #messages .maintenance.unfolded .description {
  display: block;
}

textarea.autogrow-loaded {
  resize: none;
  max-height: 50vh;
  min-height: 50px;
}

ol.timeline {
  text-align: center;
  position: relative;
  display: block;
  margin-top: 18px;
  height: 4px;
  background-color: #dddddd;
}
ol.timeline li.timeline {
  margin: auto;
  position: relative;
  top: -9px;
  display: inline-block;
}
ol.timeline li.timeline::before {
  content: "";
  cursor: pointer;
  display: block;
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  border: 4px solid #bdbdbd;
  border-radius: 50%;
  background-color: #eeeeee;
  box-shadow: 0 0 3px white, 0 0 2px white, 0 0 1px white;
}
ol.timeline li.timeline.active::before {
  background-color: #5CA7A1;
}
ol.timeline li.timeline:hover::before {
  background-color: #335c67;
}

#login-pt-content {
  min-height: 245px;
}
#login-pt-content #pt-logo {
  background: url("../images/aconium-Logo-20241.png") no-repeat center;
  background-size: 100% auto;
}

@media only screen and (max-width: 1550px) and (min-width: 400px) {
  #login {
    display: block;
  }
  #login-main-content {
    background-size: cover !important;
  }
  #login-main-content div.formbackground {
    background-color: rgba(51, 92, 103, 0.9137254902);
    position: relative;
  }
}
@media screen and (min-width: 400px) and (max-width: 991px) {
  #login-pt-content #pt-logo {
    height: 220px;
    padding: 10px;
    background-size: 22% auto;
  }
}
@media screen and (min-width: 580px) and (max-width: 680px) {
  #login-pt-content #pt-logo {
    background-size: 25% auto;
  }
}
#login-main-content {
  min-height: 450px;
  background-size: auto 100%;
  background-position-x: right !important;
  background: #335c67 url("../images/startpage_image_2.jpg") no-repeat;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
#login-main-content p {
  max-width: 800px;
  margin-right: 20px;
}

#login-logo-content {
  padding: 0;
  margin: 0;
  flex-basis: 46.7% !important;
  max-width: 46.7% !important;
}

#login-main-top-content {
  margin-top: 20px;
}

#login-inputgroup-second {
  display: block;
}

form#login {
  position: absolute;
  bottom: 10px;
}
form#login .spaced-button-group {
  padding-top: 20px;
  padding-bottom: 20px;
}
form#login .spaced-button-group .btn {
  margin-bottom: 5px;
}

form#login .btn-max {
  width: 100%;
  text-align: center;
}

div.formbackground p {
  margin-bottom: 160px;
}
div.formbackground .alert-danger {
  margin-top: 20px;
  display: block;
  background-color: #f5dfdf !important;
}

#login-pt-content #pt-workshop-content a,
#login-pt-content #pt-pt-content a {
  display: inline-block;
  margin-top: 20px;
  color: #7A960F;
  border: 2px solid #7A960F;
  background-color: white;
}
#login-pt-content #pt-workshop-content a svg,
#login-pt-content #pt-pt-content a svg {
  fill: #7A960F;
}

@media (min-width: 1600px) {
  #login-pt-content #pt-workshop-content,
  #login-pt-content #pt-pt-content {
    padding-top: 40px;
  }
}
@media (min-width: 1921px) {
  #login-pt-content #pt-workshop-content,
  #login-pt-content #pt-pt-content {
    padding-top: 50px;
  }
}
@media (max-width: 920px) {
  #login-pt-content #pt-workshop-content,
  #login-pt-content #pt-pt-content {
    min-height: 0 !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
#page .kachel #login-main-title {
  background: transparent;
}

#map-container {
  height: 370px;
  background-image: url("../images/foerdergebietB.svg");
  background-repeat: no-repeat;
}

.card .card {
  margin-right: calc(-1.25rem - 1px);
}

.card :target {
  border-color: #335c67;
}

[data-search-children] input.dsc-input {
  display: block;
  width: 100%;
  box-sizing: border-box;
  outline: none !important;
  position: sticky;
  top: 0;
  z-index: 1;
}
[data-search-children] input.dsc-input.waiting {
  background-color: yellow;
}
[data-search-children] input.dsc-input.loading {
  background-color: orange;
  cursor: wait;
}

.hr-format-underline {
  border-top: 1mm solid #dddddd;
}

div.plZero {
  padding-left: 0;
}

div.pB3 {
  padding-bottom: 3px;
  padding-top: 3px;
}

div#content div.card-columns div.card {
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.15);
}